.oldsquad__header {
    display: flex;
}

.oldsquad__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.oldsquad__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
}

.oldsquad__header-content p {
    margin-top: 0.5rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
}

.oldsquad__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    max-width: 400px;
}

.oldsquad__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 30px;

    background: #4D4D4D;
    border: 0.2px solid #fff;
    border-radius: 5px 0px 0px 5px;
    border-color: #ffffff1c;
    padding: 0 1rem;
    outline: none;
    font-family: var(--font-family);
    font-size: 16px;
    color: #fff;
    
}

.oldsquad__header-content__input button {
    flex: 0.8;
    width: 100%;
    min-height: 30px;
    white-space: nowrap;

    padding: 0.5rem 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 500;
    background: #4D4D4D;
    border: 0.2px solid #fff;
    border-radius: 0px 5px 5px 0px;
    border-color: #ffffff1c;
    outline: none;
    cursor: pointer;
}

@media screen and (max-width: 430px) {
    .oldsquad__header-content__input {
        display: none;
    }
}