.oldsquad__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.oldsquad__brand div {
    
    max-width: 150px;
    min-width: 80px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.oldsquad__brand img {
    max-width: 40px;
    opacity: 0.6;
}