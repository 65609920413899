* {
    color: #fff;
    font-family: var(--font-family);
}

.leaderboard {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    max-width: fit-content;
}

.leaderboard__data {
    background: #ffffff1c;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    max-width: fit-content;
    padding: 0.1rem;
}

.leaderboard__title {
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0 0 0.2rem 0;
}

.leaderboard__member {
    background: var(--color-navbar);
    border-radius: 5px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    margin: 1px 5px 0 2px;
    padding: 4px;
    font-size: 14px;
}

.leaderboard__name {
    font-weight: 500;
    margin: 0.2rem 1rem 0 0;
}

.leaderboard__level {
    margin: 0.2rem 1rem 0 0;
}

.leaderboard__xp {
    margin: 0.2rem 1rem 0 0;
}

.leaderboard__member--title {
    background: none;
    font-weight: 750;
    font-size: 16px;
}