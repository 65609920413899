.weather {
    display: flex;
    margin: 0 3rem 0 3rem;
}

.weather > .weatherwidget {
    margin: 0 1rem 0 0;
}

@media screen and (max-width: 840px) {
    .weather {
        margin: 0 2rem 0 2rem;
        flex-direction: column;
    }

    .weather > .weatherwidget {
        margin: 0 0 0.5rem 0;
    }
}

@media screen and (max-width: 430px) {
    .weather {
        margin: 0 1rem 0 1rem;
    }
}