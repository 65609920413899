.weatherwidget {
    background: #4D4D4D;
    padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    max-width: fit-content;
    font-family: var(--font-family);
    font-weight: 500;
    align-items: center;
    color: #fff;
}

.weatherWidget__info {
    display: flex;
    flex-direction: column;
}

.weatherWidget__temperature {
    margin: 1rem;
}

.weatherwidget img {
    max-width: 80px;
    margin: 0 0 0 1rem; 
}


