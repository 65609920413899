.discord {
    padding: 1rem;
}

.discord__linkbox {
    background: #ffffff1c;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    max-width: fit-content;
    padding: 0.5rem;
    margin: 0 1rem 1rem 0;
    font-family: var(--font-family);
    font-weight: bold;
    cursor: pointer;
}

.discord__content {
    display: flex;
    flex-direction: row;
}

.discord__description {
    display: flex;
    flex-direction: column;
    max-width: fit-content;
    margin: 0 1rem 0 0
}

.discord__description h1 {
    font-size: 1.2rem;
    font-weight: bold;
}

.discord__description p {
    font-size: 0.9rem;
    margin: 0.2rem 0 1rem 0.2rem
}

@media screen and (max-width: 430px) {


    .discord__content {
        flex-direction: column;
    }

    .discord__leaderboard {
        align-items: center;
    }

    .leaderboard {
        align-items: center;
        max-width: 100%;  
    }
}